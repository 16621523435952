import { createContextProvider } from '@solid-primitives/context'
import { createEffect, createSignal, on } from 'solid-js'

const [VirtualContextProvider, useVirtualContext] = createContextProvider(() => {
  const [isMobile, setIsMobile] = createSignal<boolean>()
  const [scroller, setScroller] = createSignal<Scroller>()
  const [scrollHeight, setScrollHeight] = createSignal(0)
  const [scrollElement, setScrollElement] = createSignal<HTMLElement>()
  const [scrollTop, setScrollTop] = createSignal(0)
  const [slider, setSlider] = createSignal<KeenSliderInstance>()

  const update = (slider: KeenSliderInstance) => {
    const height = [...slider.slides].reduce((sum, slide) => {
      const style = getComputedStyle(slide)
      const { marginBottom, marginTop } = style
      const element = slide.offsetHeight + parseFloat(marginTop) + parseFloat(marginBottom)
      return sum + element
    }, 0)
    setScrollHeight(height)
  }

  createEffect(on(slider, slider => {
    if (!slider) return
    update(slider)
  }, { defer: true }))

  createEffect(on(scrollTop, scrollTop => {
    const element = scrollElement()
    if (!element) return
    element.scrollTop = scrollTop
  }, { defer: true }))

  return {
    isMobile,
    scrollElement,
    scrollHeight,
    scrollTop,
    scroller,
    setIsMobile,
    setScrollElement,
    setScrollHeight,
    setScrollTop,
    setScroller,
    setSlider,
    slider,
    update
  }
})

export { VirtualContextProvider, useVirtualContext }
