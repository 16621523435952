import { splitProps } from 'solid-js'
import { css, ux } from '~/libs'

type Props = ComponentProps<'button'> & { classList?: Record<string, boolean> }

export function Button(props: Props) {
  const [{ children, class: root = '' }, attr] = splitProps(props, ['children', 'class'])
  const styled = {
    root: css({
      ...ux({ cursor: 'pointer' })
    })
  }
  const classes = [styled.root, root].filter(Boolean).join(' ')
  return (
    <button class={classes} {...attr}>
      {children}
    </button>
  )
}
