import icon from '~/assets/origin/square.png'
import { A, Div, Img } from '~/components/html'
import { css, font, layout, position, shape, size, space } from '~/libs'

export function VirtualHeader() {
  const { VITE_VIRTUAL_LAYOUT_WIDTH } = import.meta.env
  const styled = {
    container: css({
      ...layout({ alignItems: 'center', display: 'flex' }),
      ...space({ columnGap: '17px' }),
      ...size({ width: VITE_VIRTUAL_LAYOUT_WIDTH })
    }),
    root: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...position({ left: 0, position: 'fixed', top: 0, zIndex: 99999 + 2 }),
      ...shape({ backgroundColor: '#505050' }),
      ...size({ height: '34px', width: '100%' })
    }),
    text: {
      description: css({
        ...font({ color: '#fff', fontSize: '13px', fontWeight: 600, lineHeight: 1.2, textDecoration: 'underline' })
      })
    }
  }
  return (
    <Div class={styled.root}>
      <Div class={styled.container}>
        <Img height={34} src={icon} width={34} />
        <A
          class={styled.text.description}
          href='https://apps.apple.com/us/app/コンシェル塾-concier109/id64738337755'
          target='_blank'
        >
          App Store にて Concier109 東京版 を先行リリース
        </A>
      </Div>
    </Div>
  )
}
