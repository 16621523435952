import { splitProps } from 'solid-js'
import { A as SolidStartA } from 'solid-start'
import { css, ux } from '~/libs'

type Props = ComponentProps<typeof SolidStartA> & { useHTML?: boolean }

export function A(props: Props) {
  const [{ children, class: root = '', useHTML = false }, attr] = splitProps(props, ['children', 'class', 'useHTML'])
  const styled = {
    root: css({
      ...ux({ cursor: 'pointer' })
    })
  }
  const classes = [styled.root, root].filter(Boolean).join(' ')
  return useHTML
    ? <a class={classes} {...attr as ComponentProps<'a'>}>{children}</a>
    : <SolidStartA class={classes} {...attr}>{children}</SolidStartA>
}
