import { splitProps } from 'solid-js'
import { Meta as SolidMeta } from 'solid-start'

type Props = ComponentProps<'meta'> & { useHTML?: boolean }

export function Meta(props: Props) {
  const [, attributes] = splitProps(props, ['children'])
  const [, attr] = splitProps(attributes, ['useHTML'])
  return props.useHTML ? <meta {...attr} /> : <SolidMeta {...attr} />
}
