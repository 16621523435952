import { createResizeObserver } from '@solid-primitives/resize-observer'
import { For, Match, Show, Switch, createMemo, onMount } from 'solid-js'
import { isServer } from 'solid-js/web'
import { useLocation, useServerContext } from 'solid-start'
import { UAParser } from 'ua-parser-js'
// import a from '~/assets/backdrop/a.png'
// import b from '~/assets/backdrop/b.png'
// import c from '~/assets/backdrop/c.png'
// import d from '~/assets/backdrop/d.png'
// import e from '~/assets/backdrop/e.png'
// import f from '~/assets/backdrop/f.png'
// import g from '~/assets/backdrop/g.png'
// import h from '~/assets/backdrop/h.png'
import { Div } from '~/components/html'
import { VirtualFooter, VirtualHeader } from '~/components/ui'
import { useVirtualContext } from '~/contexts'
import { css, layout, position, shape, size, ux } from '~/libs'

type Props = ComponentProps<'div'> & { isRoot?: true }
type PresenterProps = Props & { isMobile: Accessor<boolean> }

const { VITE_VIRTUAL_LAYOUT_HEIGHT, VITE_VIRTUAL_LAYOUT_WIDTH } = import.meta.env

export function VirtualLayout(props: Props) {
  const ctx = useVirtualContext()
  if (!ctx) throw new Error('ctx is not defined')
  const event = useServerContext()
  const parser = isServer ? new UAParser(event.request.headers.get('user-agent') ?? '') : new UAParser()
  const deviceType = parser.getResult().device.type
  const ua = parser.getUA()
  const isMobile = createMemo(() => {
    return ['mobile', 'tablet', 'smarttv'].includes(deviceType ?? '') && ua.indexOf('iPad') >= -1 &&
      ua.indexOf('Macintosh') >= -1
  })
  ctx.setIsMobile(isMobile)
  onMount(() => {
    createResizeObserver(document.body, () => {
      const parser = new UAParser()
      const nextDeviceType = parser.getResult().device.type
      if (deviceType === nextDeviceType) return
      location.reload()
    })
  })
  return <Presenter isMobile={isMobile} {...props} />
}

function Presenter({ children, isMobile, isRoot }: PresenterProps) {
  const styled = {
    real: {
      root: css({
        ...layout({ display: 'grid' }),
        ...size({ height: '100%' })
      })
    },
    virtual: {
      container: css({
        ...layout({ display: 'grid', gridTemplateRows: '1fr' }),
        ...position({ position: 'relative', zIndex: 0 }),
        ...size({ height: VITE_VIRTUAL_LAYOUT_HEIGHT, width: VITE_VIRTUAL_LAYOUT_WIDTH })
      }),
      root: css({
        ...layout({ display: 'grid', placeContent: 'center' }),
        ...size({ height: '100%' })
      }),
      shatterproof: css({
        // 99999 is #party-js-container
        ...position({ left: 0, position: 'fixed', top: 0, zIndex: 99999 + 1 }),
        ...layout({
          display: 'grid',
          gridTemplateColumns: `1fr ${VITE_VIRTUAL_LAYOUT_WIDTH} 1fr`,
          gridTemplateRows: `1fr ${VITE_VIRTUAL_LAYOUT_HEIGHT} 1fr`
        }),
        ...size({ height: '100%', width: '100%' }),
        ...ux({ pointerEvents: 'none' }),
        '& > div:not(:nth-child(5))': {
          ...shape({ backgroundColor: '#333', backgroundSize: 'cover' })
        },
        '& > div:nth-child(1)': {
          ...position({ position: 'relative' }),
          // ...shape({ backgroundImage: `url(${a})` }),
          '&::after': {
            ...position({ bottom: 0, content: '""', position: 'absolute', right: 0 }),
            ...size({ height: '12px', width: '12px' }),
            ...shape({ backgroundColor: '#fff', borderRadius: '12px 0 0 0' })
          }
        },
        '& > div:nth-child(2)': {
          ...shape({ /*backgroundImage: `url(${b})`,*/ borderBottom: '12px solid #fff' })
        },
        '& > div:nth-child(3)': {
          ...position({ position: 'relative' }),
          //...shape({ backgroundImage: `url(${c})` }),
          '&::after': {
            ...position({ bottom: 0, content: '""', left: 0, position: 'absolute' }),
            ...size({ height: '12px', width: '12px' }),
            ...shape({ backgroundColor: '#fff', borderRadius: '0 12px 0 0' })
          }
        },
        '& > div:nth-child(4)': {
          ...shape({ /*backgroundImage: `url(${d})`,*/ borderRight: '12px solid #fff' })
        },
        '& > div:nth-child(5)': {},
        '& > div:nth-child(6)': {
          ...shape({ /*backgroundImage: `url(${e})`,*/ borderLeft: '12px solid #fff' })
        },
        '& > div:nth-child(7)': {
          ...position({ position: 'relative' }),
          //...shape({ backgroundImage: `url(${f})` }),
          '&::after': {
            ...position({ content: '""', position: 'absolute', right: 0, top: 0 }),
            ...size({ height: '12px', width: '12px' }),
            ...shape({ backgroundColor: '#fff', borderRadius: '0 0 0 12px' })
          }
        },
        '& > div:nth-child(8)': {
          ...shape({ /*backgroundImage: `url(${g})`,*/ borderTop: '12px solid #fff' })
        },
        '& > div:nth-child(9)': {
          ...position({ position: 'relative' }),
          //...shape({ backgroundImage: `url(${h})` }),
          '&::after': {
            ...position({ content: '""', left: 0, position: 'absolute', top: 0 }),
            ...size({ height: '12px', width: '12px' }),
            ...shape({ backgroundColor: '#fff', borderRadius: '0 0 12px 0' })
          }
        }
      })
    }
  }
  const routes = {
    root: css({
      ...shape({ backgroundColor: '#ebebeb' })
    })
  }
  return (
    <Switch>
      <Match when={!isMobile()}>
        <Div class={styled.virtual.root} classList={{ [routes.root]: isRoot }}>
          <Div class={styled.virtual.container}>
            {children}
          </Div>
        </Div>
        <Div class={styled.virtual.shatterproof}>
          <For each={[...Array(9).keys()]}>{() => <Div />}</For>
        </Div>
        <VirtualHeader />
        <Show when={!useLocation().pathname.includes('/share')}>
          <VirtualFooter />
        </Show>
      </Match>
      <Match when={isMobile()}>
        <Div class={styled.real.root} classList={{ [routes.root]: isRoot }}>
          {children}
        </Div>
      </Match>
    </Switch>
  )
}
