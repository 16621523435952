import { useLocation, useNavigate } from 'solid-start'
import { Button, Div } from '~/components/html'
import { css, font, layout, position, shape, size, space } from '~/libs'

export function VirtualFooter() {
  const { VITE_APP_URL, VITE_VIRTUAL_LAYOUT_WIDTH } = import.meta.env
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const go = () => {
    const path = `${VITE_APP_URL}${pathname}`
    const uri = encodeURIComponent(path)
    navigate(`/share/${uri}${location.search}`)
  }
  const styled = {
    container: css({
      ...layout({ alignItems: 'center', display: 'flex', justifyContent: 'center' }),
      ...space({ columnGap: '17px' }),
      ...size({ width: VITE_VIRTUAL_LAYOUT_WIDTH })
    }),
    root: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...position({ bottom: 0, left: 0, position: 'fixed', zIndex: 99999 + 2 }),
      ...shape({ backgroundColor: '#505050' }),
      ...size({ height: '34px', width: '100%' })
    }),
    text: {
      description: css({
        ...font({ color: '#fff', fontSize: '13px', fontWeight: 600, lineHeight: 1.2, textDecoration: 'underline' })
      })
    }
  }
  return (
    <Div class={styled.root}>
      <Div class={styled.container}>
        <Button class={styled.text.description} onClick={go}>
          この画面をお使いのスマートフォンやタブレットに共有する
        </Button>
      </Div>
    </Div>
  )
}
